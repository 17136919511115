import * as PIXI from "pixi.js";
import { Stage, Container, Sprite, Text, AnimatedSprite } from "@pixi/react";

import { useMemo, useEffect, useState, useRef } from "react";

export const PixiLanding = () => {
  // const [pixiapp, setPixiapp] = useState(new PIXI.Application());
  const canvasRef = useRef(null);
  const bunnyUrl = "https://pixijs.io/pixi-react/img/bunny.png";

  useEffect(() => {
    async function initpixi() {
      // await pixiapp.init({ background: "#1099bb", resizeTo: window });
      // await pixiapp.init({ background: "#1099bb" });
      // document.getElementById("landingcanvas")?.appendChild(pixiapp.canvas);
      const app = new PIXI.Application({
        width:800,
        height:900
      });
      // canvasRef.current.appendChild(app.view);
    }
    initpixi();
  } );

  return (
    <div id="landingcanvas"></div>
    // <Stage width={800} height={600} options={{ background: 0x1099bb }}>
    //   <Sprite image={bunnyUrl} x={300} y={150} />
    //   <Sprite image={bunnyUrl} x={500} y={150} />
    //   <Sprite image={bunnyUrl} x={400} y={200} />

    //   <Container x={200} y={200}></Container>
    // </Stage>
  );
};
