import { Fragment } from "react";
import { Footer } from "../ReactComponents/components/Footer";
import NavbarLeft from "../ReactComponents/components/NavbarLeft";
import NavbarTop from "../ReactComponents/components/NavbarTop";
import PortfolioContent from "../ReactComponents/container/portfolioContent";
import { Container } from "react-bootstrap";
import { JSX } from "react/jsx-runtime";
interface props {
  content?: JSX.Element;
}
export default function PortfolioLayout(props: props) {
  return (
    <div>
      <Container className="g-0" fluid>
        {/* <NavbarLeft /> */}
        <PortfolioContent>
          <Fragment>
            <NavbarTop />
            <div>{props.content}</div>
            <Footer />
          </Fragment>
        </PortfolioContent>
      </Container>
    </div>
  );
}
