import { useEffect, useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
interface TechCard {
  title: string;
  iconUrl: string;
  description: string[];
  yearsOfExp: string;
}

const TechStackCard = (props: TechCard) => {
  return (
    <div className="tech-stack-content-card">
      <Card className="text-center">
        <Card.Header>{props.title}</Card.Header>
        <Card.Img
          className="tech-stack-content-card-img"
          variant="top"
          src={props.iconUrl}
        />
        <hr />
        <Card.Body>
          <ul>
            {props.description.map((d, i) => (
              <li className="tech-stack-card-des" key={`tscctcc-${i}`}>
                {d}
              </li>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer className="text-muted">{props.yearsOfExp}</Card.Footer>
      </Card>
    </div>
  );
};

interface TechStackContainerData {
  titles: string[];
  cardsData: TechCard[];
}

const TechStackContainer = (data: TechStackContainerData[]) => {
  return (
    <Container>
      <section className="tech-stack-section">
        {data.map((e, i) => {
          return (
            <Container fluid="lg" key={`tech-stack-section-container-${i}`}>
              {e.titles.map((titleText, titleInd) => (
                <h2
                  className="section_heading d-flex justify-content-center"
                  key={`tech-stack-section-${titleText}-${titleInd}`}
                >
                  {titleText}
                </h2>
              ))}

              <div className="tech-stack-content">
                <Row className="g-4 justify-content-center">
                  {e.cardsData.map((cardData, cardInd) => {
                    return (
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        key={`${e.titles.map((t) => t)}-${i}-card-${cardInd}`}
                      >
                        <TechStackCard {...cardData} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Container>
          );
        })}
      </section>
    </Container>
  );
};

export const TechStack = () => {
  const [techCardData, setTechCardData] = useState<TechStackContainerData[]>(
    []
  );

  useEffect(() => {
    const getTechSkills = async () => {
      const skillsList = [];

      const professionalSkillsDataRes = await fetch(
        "/Data/TechSkillCards/ProfessionalSkillsData.json"
      );
      const professionalSkillsDataJson = await professionalSkillsDataRes.json();
      skillsList.push({
        titles: professionalSkillsDataJson["title"],
        cardsData: professionalSkillsDataJson["data"],
      });

      const hobbyistSkillsDataRes = await fetch(
        "/Data/TechSkillCards/HobbyistSkillsData.json"
      );
      const hobbyistSkillsDataJson = await hobbyistSkillsDataRes.json();
      skillsList.push({
        titles: hobbyistSkillsDataJson["title"],
        cardsData: hobbyistSkillsDataJson["data"],
      });

      const knowledgableSkillsDataRes = await fetch(
        "/Data/TechSkillCards/KnowledgableSkillsData.json"
      );
      const knowledgableSkillsJson = await knowledgableSkillsDataRes.json();
      skillsList.push({
        titles: knowledgableSkillsJson["title"],
        cardsData: knowledgableSkillsJson["data"],
      });

      setTechCardData(skillsList);
    };

    getTechSkills();
  }, []);

  return TechStackContainer(techCardData);
};
