import React from "react";
const buildConfig = {
  local: true,
  serverUrl: "https://jinming.ca/",
  // serverUrl: "http://10.0.0.91:3000/",
  wolfIconUrl: "/WolfIcons/wolf_icon_{}.png",
  wolfIconCount: 337,
};

class _DataCenter {
  public static async getProfilePicUrl() {
    const res = await fetch("/configs/bibliographyInfo.json");
    const biblioJson = await res.json();
    return biblioJson;
  }
}
const NetworkConfig = React.createContext(buildConfig);
const AppConfig = React.createContext(null);

export { NetworkConfig, AppConfig, buildConfig, _DataCenter };
