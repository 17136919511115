import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Fragment } from "react";

const TimelineGap = () => {
  const hideTimeSize = 990;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const gap = (
    <Row>
      <Col xs={2} className="timeline-line" style={{ height: 40 }}></Col>
      <Col />
    </Row>
  );

  return <Fragment>{screenWidth > hideTimeSize ? gap : null}</Fragment>;
};
export default TimelineGap;
