import { Container, Nav, Navbar } from "react-bootstrap";
import WolfIcon from "./base/WolfIcon";
import { useEffect, useRef, useState } from "react";
interface NavlinkItemProp {
  refLink: string;
  content: string;
}
const NavlinkItem = (props: NavlinkItemProp) => (
  <Nav.Link className="text-center" style={navItemStyle} href={`/#${props.refLink}`}>
    {props.content}
  </Nav.Link>
);
function NavbarTop() {
  const [height, setHeight] = useState(0);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (navbarRef?.current != null) {
      if (!isNaN(navbarRef.current.clientHeight)) {
        setHeight(navbarRef.current.clientHeight - 15);
      }
    }
  }, []);

  return (
    <Navbar sticky="top" ref={navbarRef} expand="xl" className="bg-body-tertiary ">
      <Container>
        <Navbar.Brand href="/">
          <WolfIcon height={height} width={height} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="flex justify-content-center align-items-center"
        >
          <Nav>
            <NavlinkItem refLink="/" content="Home" />
            <NavlinkItem refLink="/projects" content="Projects" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavbarTop;

const navItemStyle: React.CSSProperties = {
  marginRight: "3rem",
  fontSize: "2rem",
};
