import { useEffect, useState } from "react";
import { _DataCenter } from "../../DataCenter";
import { Container, Row } from "react-bootstrap";

interface bibliographyInfo {
  title: string;
  profileIconUrl: string;
  descriptions: string[];
}

export default function Bibliography() {
  const [biblioInfo, setBibioInfo] = useState<bibliographyInfo>();
  const [fetchedData, setFetchedData] = useState(false);
  useEffect(() => {
    async function getIcon() {
      let data = {} as bibliographyInfo;
      try {
        data = await _DataCenter.getProfilePicUrl();
      } catch (error) {
        console.log(error);
        return;
      }
      let info = data;
      const imgBlob = await (await fetch(data.profileIconUrl)).blob();
      info.profileIconUrl = URL.createObjectURL(imgBlob);
      setBibioInfo(info);
      setFetchedData(true);
    }
    if (fetchedData) {
      return;
    }
    getIcon();
  }, [fetchedData]);

  return (
    <Container>
      <Row>
        <section id="bibliograph" className="align-content-center">
          <div className="my-pic justify-content-start">
            <img src={biblioInfo?.profileIconUrl} alt="cant find me..." />
          </div>
          <div id="bibli-title" style={titleStyle}>
            {biblioInfo?.title}
          </div>
          <div className="my-bibli">
            {biblioInfo?.descriptions?.map((des, i) => {
              return (
                <p style={parastyle} key={`profile-des-${i}`}>
                  {des}
                </p>
              );
            })}
          </div>
        </section>
      </Row>
    </Container>
  );
}

const titleStyle: React.CSSProperties = {
  fontSize: "5rem",
  fontWeight: "1000",
  textShadow: "#FC0 1px 0 10px",
};
const parastyle: React.CSSProperties = {
  fontSize: "1.1rem",
  textShadow: "#eee 1px 0 10px",
};
