import React, { useContext } from "react";

import { NetworkConfig } from "../../../DataCenter";
interface props {
  width?: number;
  height?: number;
}
/**
 * A randomized wolf icon on every re-render based on the root url and the maximum index to random from.
 */

export default function WolfIcon(props: props) {
  const dat = useContext(NetworkConfig);
  const url = dat.wolfIconUrl.replace(
    "{}",
    Math.floor(Math.random() * dat.wolfIconCount).toString()
  );

  const containerStyle: React.CSSProperties = {
    height: props?.height == null ? "100%" : props.height,
    width: props.width == null ? "100%" : props.width,
  };

  const iconStyle: React.CSSProperties = {
    objectFit: "contain",
    height: "100%",
    width: "100%",
    objectPosition: "50% 50%",
  };
  return (
    <div id="wolf_icon" style={containerStyle}>
      <img src={url} alt="wolfy" style={iconStyle} />
    </div>
  );
}
